<template>
  <Transition
    :enterActiveClass="transition.enter"
    :leaveActiveClass="transition.leave"
  >
    <div
      v-show="isActive"
      ref="root"
      class="v-toast__container"
      :class="[`v-toast__container--${position}`]"
      data-mf
      @mouseover="toggleTimer(true)"
      @mouseleave="toggleTimer(false)"
      @click="handleClick"
    >
      <slot />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, render, toRef } from 'vue'

import { ToastContainerProps } from '@lasso/shared/hooks'

import { useProvideToastState } from '../useToastState'

import Timer from './timer'

const props = defineProps<ToastContainerProps>()

const emit = defineEmits<{
  click: []
  dismiss: []
}>()

const isActive = ref(false)
const parentTop = ref<HTMLElement | null>(null)
const parentBottom = ref<HTMLElement | null>(null)
const queueTimer = ref<number>()
const root = ref()
const timer = ref<Timer>()

const parent = computed(() => {
  if (['top', 'top-right', 'top-left'].includes(props.position)) {
    return parentTop.value
  }
  return parentBottom.value
})

const transition = computed(() => {
  if (['top', 'top-right', 'top-left'].includes(props.position)) {
    return {
      enter: 'v-toast--fade-in-down',
      leave: 'v-toast--fade-out',
    }
  }

  return {
    enter: 'v-toast--fade-in-up',
    leave: 'v-toast--fade-out',
  }
})

const setupContainer = () => {
  parentTop.value = document.querySelector('.v-toast.v-toast--top')
  parentBottom.value = document.querySelector('.v-toast.v-toast--bottom')
  // No need to create them, if they already exist
  if (parentTop.value && parentBottom.value) {
    return
  }

  if (!parentTop.value) {
    parentTop.value = document.createElement('div') as HTMLElement
    parentTop.value.classList.add('v-toast')
    parentTop.value.classList.add('v-toast--top')
    parentTop.value.style.zIndex = `${props.zIndex}`
  }

  if (!parentBottom.value) {
    parentBottom.value = document.createElement('div') as HTMLElement
    parentBottom.value.classList.add('v-toast')
    parentBottom.value.classList.add('v-toast--bottom')
    parentBottom.value.style.zIndex = `${props.zIndex}`
  }
  const container = props.target ? document.querySelector(props.target) : document.body
  container?.appendChild(parentTop.value)
  container?.appendChild(parentBottom.value)
}

const removeElement = (el: HTMLElement) => {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  }
  else {
    el.parentNode?.removeChild(el)
  }
}

const getShouldQueue = () => {
  if (!props.queue)
    return false

  return (
    (parentTop.value?.childElementCount && parentTop.value?.childElementCount > 0)
    || (parentBottom.value?.childElementCount && parentBottom.value?.childElementCount > 0)
  )
}

const dismiss = () => {
  if (timer.value) {
    timer.value.stop()
  }
  clearTimeout(queueTimer.value)
  isActive.value = false

  // Timeout for the animation complete before destroying
  setTimeout(() => {
    emit('dismiss')
    const wrapper = root.value

    // unmount the component
    render(null, wrapper)
    removeElement(wrapper)
  }, 150)
}

const showNotice = () => {
  if (getShouldQueue()) {
    // Call recursively if it should queue
    queueTimer.value = window.setTimeout(showNotice, 250)
    return
  }

  const wrapper = root.value.parentElement
  parent.value?.insertAdjacentElement('afterbegin', root.value)
  removeElement(wrapper)
  isActive.value = true

  if (props.duration) {
    timer.value = new Timer(dismiss, props.duration)
  }
}

const handleClick = () => {
  emit('click')
}

const toggleTimer = (newVal: boolean) => {
  if (!props.pauseOnHover || !timer.value) {
    return
  }
  newVal ? timer.value.pause() : timer.value.resume()
}

useProvideToastState({
  dismissible: toRef(props, 'dismissible'),
  dismiss,
})

onMounted(() => {
  setupContainer()
  showNotice()
})

defineExpose({
  dismiss,
})
</script>

<!-- eslint-disable vue/enforce-style-attribute -->
<style src="./toastContainer.styles.css" />
