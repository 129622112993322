import { createInjectionState } from '@lasso/shared/utils'
import { Ref, ref } from 'vue'

export const [useProvideToastState, useToastState] = createInjectionState(
  (state: {
    dismissible: Readonly<Ref<boolean>>
    dismiss: () => void
  }) => {
    return state
  },
  {
    fallback: () => ({
      dismissible: ref(false),
      dismiss: () => {},
    }),
  },
)
