<template>
  <Box v-if="multiple && options.length > 0" flex wrap="wrap" space="1" width="100%" class="overflow-x-hidden">
    <Chip
      v-for="(item, index) in options"
      :key="index"
      size="2xs"
      color="secondary"
      variant="outlined"
      class="max-w-full"
      :disabled="disabled"
      cancelable
      @cancel="onCancel(item)"
    >
      <span class="truncate">
        {{ item.label }}
      </span>
    </Chip>
  </Box>
  <Box
    v-else
    flex justify="between" alignItems="center" spaceX="2" width="100%"
  >
    <Typography
      variant="body2"
      :disabled="disabled"
      color="textPrimary"
      truncateRow="one"
    >
      {{ label }}
    </Typography>
    <Icon
      v-if="cancelable"
      color="textSecondaryLight"
      variant="outlined"
      icon="close"
      data-test-id="selected-value-clear"
      @click.stop="onCancel(selectedOption)"
    />
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Chip from '../Chip/Chip.vue'
import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'
import Icon from '../Icon/Icon.vue'

import type { SelectOptionType } from './types'

type Props = {
  options: SelectOptionType[]
  multiple?: boolean
  disabled?: boolean
  cancelable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  multiple: false,
  disabled: false,
  cancelable: false,
})

const emits = defineEmits(['cancel'])

const onCancel = (item: SelectOptionType | null) => {
  if (!props.disabled) {
    emits('cancel', item)
  }
}

const selectedOption = computed(() => props.options?.[0] || null)

const label = computed(() => selectedOption.value?.label || selectedOption?.value?.value || '')
</script>
